const primary = '#84C44C'
const secondary = '#1F4B34'
const error = '#F32424'

export default {
    palette: {
        primary: {
            main: primary,
            contrastText: '#fff',
        },
        secondary: {
            main: secondary,
            dark: '#1B3D2B',
            contrastText: '#fff',
        },
        error: {
            main: error,
            contrastText: '#fff',
        },
        text: {
            primary: '#84C44C',
            secondary: '#007235',
            red: error,
            black: '#363636',
            grey: '#989898',
        },
        background: {
            default: '#FFFFFF',
        },
        border: {
            default: '#DFDFDF',
        },
        black: {
            default: '#363636',
        },
    },
}
