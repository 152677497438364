import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider as StyledComponetsThemeProvider } from 'styled-components'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import 'react-toastify/dist/ReactToastify.css'
import Themes from './themes'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { store, persistor } from './store'

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <StyledComponetsThemeProvider theme={Themes.default}>
                <ThemeProvider theme={Themes.default}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </StyledComponetsThemeProvider>
            <ToastContainer autoClose={3000} />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)

serviceWorker.unregister()
