import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

function Loading({ size, ...rest }) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress size={size} {...rest} />
        </div>
    )
}

export default Loading
