import React from 'react'

import useStyles from './styles'

import Sidebar from '../../components/Sidebar'
import Routes from '../../route/Routes'
import Menu from '../Menu'

function Layout() {
    const classes = useStyles()
    const [open, setOpen] = React.useState(true)

    const handleDrawerOpen = () => {
        setOpen(!open)
    }
    return (
        <>
            <div className={classes.root}>
                <Sidebar auth={true} open={open} setOpen={handleDrawerOpen} />
                <div className={classes.content}>
                    <Menu open={open} />
                    <div className={classes.container}>
                        <Routes />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout
