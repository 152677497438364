import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
    profileMenu: {
        minWidth: '12rem',
        borderRadius: 0,
        boxShadow: '2px 2px 8px #929292',
    },
    logout: {
        color: theme.palette.text.red,
    },
}))
