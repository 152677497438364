import { lazy } from 'react'
const HomePage = lazy(() => import('./home/index'))
const Profile = lazy(() => import('./profile/index'))
const NotFound = lazy(() => import('./notFound/index'))

// Funcionários
const Employees = lazy(() => import('./employees/Employees'))
const NewEmployee = lazy(() => import('./employees/NewEmployee'))
const EditEmployee = lazy(() => import('./employees/EditEmployee'))
const ViewEmployee = lazy(() => import('./employees/ViewEmployee'))

// Usuários
const Users = lazy(() => import('./users/Users'))
const NewUser = lazy(() => import('./users/NewUser'))
const EditUser = lazy(() => import('./users/EditUser'))
const ViewUser = lazy(() => import('./users/ViewUser'))

// Produtos
const Products = lazy(() => import('./product/Products'))
const NewProduct = lazy(() => import('./product/NewProduct'))
const EditProduct = lazy(() => import('./product/EditProduct'))
const ViewProduct = lazy(() => import('./product/ViewProduct'))

// Ofertas
const Ofertas = lazy(() => import('./ofertas/Ofertas'))
const NewOferta = lazy(() => import('./ofertas/NewOferta'))
const EditOferta = lazy(() => import('./ofertas/EditOferta'))
const ViewOferta = lazy(() => import('./ofertas/ViewOferta'))

// Negociação
const Negotiations = lazy(() => import('./negotiation/Negotiations'))
const Chat = lazy(() => import('./chat/ViewChat'))

export const routes = [
    {
        exact: false,
        private: true,
        path: '/home',
        component: HomePage,
    },
    {
        exact: false,
        private: true,
        path: '/profile',
        component: Profile,
    },
    {
        exact: false,
        private: true,
        path: '/profile',
        component: Profile,
    },
    {
        exact: false,
        private: true,
        path: '/dashboard-admin',
        component: Employees,
    },
    {
        exact: false,
        private: true,
        path: '/employees',
        component: Employees,
    },
    {
        exact: false,
        private: true,
        path: '/new-employee',
        component: NewEmployee,
    },
    {
        exact: false,
        private: true,
        path: '/view-employee/:id',
        component: ViewEmployee,
    },
    {
        exact: false,
        private: true,
        path: '/edit-employee/:id',
        component: EditEmployee,
    },
    {
        exact: false,
        private: true,
        path: '/dashboard',
        component: Users,
    },
    {
        exact: false,
        private: true,
        path: '/users',
        component: Users,
    },
    {
        exact: false,
        private: true,
        path: '/new-user',
        component: NewUser,
    },
    {
        exact: false,
        private: true,
        path: '/edit-user/:id',
        component: EditUser,
    },
    {
        exact: false,
        private: true,
        path: '/view-user/:id',
        component: ViewUser,
    },
    {
        exact: false,
        private: true,
        path: '/products',
        component: Products,
    },
    {
        exact: false,
        private: true,
        path: '/new-product',
        component: NewProduct,
    },
    {
        exact: false,
        private: true,
        path: '/edit-product/:id',
        component: EditProduct,
    },
    {
        exact: false,
        private: true,
        path: '/view-product/:id',
        component: ViewProduct,
    },
    {
        exact: false,
        private: true,
        path: '/ofertas',
        component: Ofertas,
    },
    {
        exact: false,
        private: true,
        path: '/new-oferta',
        component: NewOferta,
    },
    {
        exact: false,
        private: true,
        path: '/edit-oferta/:id',
        component: EditOferta,
    },
    {
        exact: false,
        private: true,
        path: '/view-oferta/:id',
        component: ViewOferta,
    },
    {
        exact: false,
        private: true,
        path: '/negociacao',
        component: Negotiations,
    },
    {
        exact: false,
        private: true,
        path: '/chat/:id',
        component: Chat,
    },
    {
        exact: false,
        private: true,
        path: '*',
        component: NotFound,
    },
]
