/* eslint-disable no-unneeded-ternary */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Loading from '../Loading'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    cancel: {
        background: theme.palette.error.main,
        color: '#FFF',
        width: '100%',
        minHeight: '1.5rem',

        '&:hover': {
            background: theme.palette.error.main,
            opacity: 0.8,
        },
    },
    sucess: {
        width: '100%',
        minHeight: '1.5rem',
    },
}))

function Button2({ onClick, style, label, loading, disabled, ...props }) {
    const classes = useStyles()

    return (
        <Button
            size="small"
            style={{ position: 'inherit' }}
            variant="contained"
            onClick={onClick}
            disabled={disabled || loading ? true : false}
            className={
                style === 'cancel'
                    ? classes.cancel
                    : style === 'sucess'
                    ? classes.sucess
                    : ''
            }
            {...props}
        >
            {loading ? <Loading size={24} color="#A0A0A0" /> : label}
        </Button>
    )
}

export default Button2
