/* eslint-disable no-unneeded-ternary */
import React from 'react'
import {
    ListItemIcon,
    ListItemText,
    List,
    ListItem,
    Collapse,
    Box,
} from '@material-ui/core'
import admin_img from '../../assets/icons/Menu-bar-perfil.svg'
import financeiro from '../../assets/icons/Menu-bar-financeiro.svg'
import comercial from '../../assets/icons/Menu-bar-negociações.svg'
import useStyles from './styles'
import history from '../../config/history'
import { store } from '../../store'

function MenuItem({ open, location }) {
    const classes = useStyles()
    var struture
    const { user } = store.getState().auth

    const admin = [
        {
            id: 1,
            name: 'Admin Geral',
            icon: admin_img,
            selected:
                location.pathname === '/dashboard-admin' ||
                location.pathname.indexOf('employee') > -1,
            links: [
                {
                    id: 2,
                    name: 'Gestão de Funcionários',
                    link: '/dashboard-admin',
                    selected:
                        location.pathname === '/dashboard-admin' ||
                        location.pathname.indexOf('employee') > -1,
                },
            ],
        },
        {
            id: 3,
            name: 'Comercial',
            icon: comercial,
            selected:
                location.pathname === '/dashboard' ||
                location.pathname.indexOf('user') > -1 ||
                location.pathname.indexOf('product') > -1 ||
                location.pathname.indexOf('oferta') > -1,
            links: [
                {
                    id: 4,
                    name: 'Usuários',
                    link: '/dashboard',
                    selected:
                        location.pathname === '/dashboard' ||
                        location.pathname.indexOf('user') > -1,
                },
                {
                    id: 5,
                    name: 'Produtos',
                    link: '/products',
                    selected: location.pathname.indexOf('product') > -1,
                },
                {
                    id: 6,
                    name: 'Ofertas',
                    link: '/ofertas',
                    selected: location.pathname.indexOf('oferta') > -1,
                },
            ],
        },
        {
            id: 7,
            name: 'Financeiro',
            icon: financeiro,
            selected: location.pathname.indexOf('negociacao') > -1,
            links: [
                {
                    id: 8,
                    name: 'Negociações',
                    link: '/negociacao',
                    selected: location.pathname.indexOf('negociacao') > -1,
                },
            ],
        },
    ]
    const employees = [
        {
            id: 3,
            name: 'Comercial',
            icon: comercial,
            selected:
                location.pathname === '/dashboard' ||
                location.pathname.indexOf('user') > -1 ||
                location.pathname.indexOf('product') > -1 ||
                location.pathname.indexOf('oferta') > -1,
            links: [
                {
                    id: 4,
                    name: 'Usuários',
                    link: '/dashboard',
                    selected:
                        location.pathname === '/dashboard' ||
                        location.pathname.indexOf('user') > -1,
                },
                {
                    id: 5,
                    name: 'Produtos',
                    link: '/products',
                    selected: location.pathname.indexOf('product') > -1,
                },
                {
                    id: 6,
                    name: 'Ofertas',
                    link: '/ofertas',
                    selected: location.pathname.indexOf('oferta') > -1,
                },
            ],
        },
        {
            id: 7,
            name: 'Financeiro',
            icon: financeiro,
            selected: location.pathname.indexOf('negociacao') > -1,
            links: [
                {
                    id: 8,
                    name: 'Negociações',
                    link: '/negociacao',
                    selected: location.pathname.indexOf('negociacao') > -1,
                },
            ],
        },
    ]

    if (user) {
        if (user.role === 'Administrador') {
            struture = admin
        } else if (user.role === 'Funcionário') {
            struture = employees
        }
    }

    return (
        <>
            {struture &&
                struture.map((item, index) => (
                    <div key={index}>
                        <ListItem
                            button
                            disabled={open}
                            key={index}
                            selected={!open && item.selected}
                            className={classes.menuItem}
                        >
                            <ListItemIcon
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '1.5rem',
                                }}
                            >
                                <img
                                    style={{
                                        height: '0.8rem',
                                        margin: 'auto',
                                    }}
                                    src={item.icon}
                                    alt={item.name}
                                />
                            </ListItemIcon>
                            {open && (
                                <ListItemText style={{ color: 'white' }}>
                                    {item.name}
                                </ListItemText>
                            )}
                            {!open && (
                                <List className={classes.menuSubistem}>
                                    <Box sx={{ zIndex: 'tooltip' }}>
                                        <ListItem selected>
                                            <ListItemIcon
                                                style={{
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    height: '1.5rem',
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        height: '0.8rem',
                                                        // width: '0.8rem',
                                                        margin: 'auto',
                                                    }}
                                                    src={item.icon}
                                                    alt={item.name}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                style={{
                                                    color: 'white',
                                                    marginLeft: '0px',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item.name}
                                            </ListItemText>
                                        </ListItem>
                                        {item.links.map((link, index) => (
                                            <ListItem
                                                key={index}
                                                selected={
                                                    link.selected ? true : false
                                                }
                                                button
                                                onClick={() =>
                                                    history.push(link.link)
                                                }
                                            >
                                                <ListItemText
                                                    style={{
                                                        marginLeft: '2.3rem',
                                                        fontSize: '0.9rem',
                                                    }}
                                                    primary={link.name}
                                                />
                                            </ListItem>
                                        ))}
                                    </Box>
                                </List>
                            )}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {item.links.map((link) => (
                                    <ListItem
                                        key={link.link}
                                        selected={link.selected ? true : false}
                                        button
                                        onClick={() => history.push(link.link)}
                                    >
                                        <ListItemText
                                            className={classes.ml2}
                                            primary={link.name}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    </div>
                ))}
        </>
    )
}

export default MenuItem
