import { Box, makeStyles } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/Sidebar'
import Loading from '../../components/Loading'
import history from '../../config/history'
import { store } from '../../store'

const useStyles = makeStyles(() => ({
    container: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundColor: 'rgba(0, 0, 0,0.7)',
    },
}))

function Home() {
    const [loading, setLoading] = useState(true)
    const classes = useStyles()
    const { user } = store.getState().auth
    useEffect(() => {
        if (user && user.role === 'Administrador') {
            history.push('/dashboard-admin')
        } else {
            history.push('/dashboard')
        }
        setLoading(false)
    }, [])

    return (
        <>
            <Sidebar />
            <Box className={classes.container}>{loading && <Loading />}</Box>
        </>
    )
}

export default Home
