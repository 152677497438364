import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
    menuClose: {
        width: '3rem',
        maxWidth: '3rem',
    },
    menuOpen: {
        minWidth: '14.5rem',
        width: '14.5rem',
    },
    sidebar: {
        height: '100%',
        backgroundColor: '#1F4B34',
    },
}))
