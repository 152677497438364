import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { store } from '../../store/'

function RouteWrapper({ component: Component, isPrivate, ...rest }) {
    const { signed } = store.getState().auth

    const pathname = useLocation().pathname

    if (pathname.includes('recuperar-senha')) {
        return <Route {...rest} component={Component} />
    }
    if (!signed && isPrivate) {
        return <Redirect to="/" />
    }

    if (signed && !isPrivate) {
        return <Redirect to="/home" />
    }
    return <Route {...rest} component={Component} />
}

export default RouteWrapper
