import axios from 'axios'
import { store } from '../store/index'
import { Creators } from '../store/ducks/auth'
require('dotenv').config()

const api = axios.create({
    // baseURL: 'http://localhost:3333/api/',
    // baseURL: 'https://dev.g3agro.com.br/api/',
    baseURL: process.env.REACT_APP_BACKEND
        ? `${process.env.REACT_APP_BACKEND}/api/`
        : '/api/',
})

api.interceptors.request.use((config) => {
    const state = store.getState()
    const { access_token } = state.auth

    if (access_token) {
        config.headers.Authorization = `Bearer ${access_token}`
    }

    return config
})
api.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        var originalConfig = error.config

        if (error.response) {
            if (
                error.response.data.message ===
                    'Acesso negado: Token expirado.' &&
                !originalConfig._retry
            ) {
                originalConfig._retry = true
                try {
                    const refresh_token = localStorage.getItem(
                        '@Geleilate: refresh_token'
                    )
                    const body = {
                        refresh_token,
                    }

                    const response = await api.post('/user/refresh', body)
                    api.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`

                    store.dispatch(Creators.setToken(response.data.accessToken))

                    return api(originalConfig)
                } catch (err) {
                    return Promise.reject(err)
                }
            }
            if (
                (error.response.data.message === 'Refresh token inválido.' ||
                    error.response.data.message ===
                        'Sua sessão expirou, por favor realize um novo login.') &&
                !originalConfig._retry
            ) {
                try {
                    store.dispatch(Creators.signOut())
                    return Promise.reject(error)
                } catch (err) {
                    return Promise.reject(err)
                }
            }
        }
        return Promise.reject(error)
    }
)
export default api
