import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
    menuItem: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.primary.contrastText,
        height: '2.5rem',
        alignItems: 'start',
        justifyContent: 'end',
        margin: 0,

        '&.MuiListItem-gutters': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        },
        '&:hover > ul': {
            minWidth: '14.5rem !important',
            marginTop: -8,
            position: 'fixed',
            left: '3rem',
            display: 'block',
        },
        '& .MuiTypography-body1': {
            fontSize: '0.9rem',
            lineHeight: '1.1rem',
        },
    },
    menuSubistem: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.primary.contrastText,
        minHeight: '2.1rem',
        display: 'none',
        '&.MuiListItem-gutters': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        },
    },
    ml2: {
        marginLeft: '1.5rem',
    },
}))
