import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import adminLogoLogin from '../../assets/images/Logo.png'
import backgroundLogin from '../../assets/images/Fundo.png'
import { Link } from 'react-router-dom'
import ToolBar from '../../components/ToolBar'
import api from '../../config/api'
import history from '../../config/history'

import { toast } from 'react-toastify'
import Button2 from '../../components/Button'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
    },
    logoContainer: {
        width: '6.25rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoImage: {
        marginBottom: theme.spacing(4),
    },
    formContainer: {
        background: theme.palette.background.default,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.border.default,
        borderRadius: 10,
        padding: '2.5rem 1.2rem',
        minWidth: 320,
        width: '23%',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        marginBottom: 19,
        fontSize: '1.2rem',

        '& .MuiInputBase-input': {
            borderBottom: '1px solid #363636',
            fontSize: '1rem',
            color: '#a9a9a9',
        },
    },
    buttonContainer: {
        marginTop: 16,
        marginBottom: 22,
        ' & .MuiButton-containedSizeLarge': {
            padding: '16px 22px',
            fontSize: '0.9rem',
            lineHeight: '1.2rem',
        },
    },
    label: { color: theme.palette.text.black },
    linkRecover: {
        marginBottom: 10,
        textAlign: 'center',
        textDecoration: 'none',
        color: theme.palette.text.grey,
        '& b': {
            color: theme.palette.primary.main,
        },
    },
}))

const validationSchema = yup.object({
    email: yup
        .string('E-mail')
        .email('Digite um endereço de e-mail válido.')
        .required('Campo Obrigatório'),
})

function RecoverPassword() {
    const [loading, setLoading] = useState(false)
    const classes = useStyles()

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            recoverPassword(values)
        },
    })

    function recoverPassword(values) {
        try {
            setLoading(true)
            api.post(`/user/recover-password/`, {
                email: values.email,
            })
                .then((response) => {
                    if (response.status === 200) {
                        toast.success(
                            'E-mail de recuperação enviado com sucesso.'
                        )
                        history.push('/')
                    }
                })
                .catch((err) => {
                    toast.error(err.response.data.message)
                })
            setLoading(false)
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message)
            } else {
                toast.error('Error no sistema! Tente novamente mais tarde.')
            }
            setLoading(false)
        }
    }

    return (
        <Grid
            container
            className={classes.container}
            style={{
                backgroundImage: `url(${backgroundLogin})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
            }}
        >
            <ToolBar />
            <div className={classes.logoContainer}>
                <img
                    src={adminLogoLogin}
                    alt="logo"
                    className={classes.logoImage}
                />
            </div>
            <div className={classes.formContainer}>
                <div className={classes.form}>
                    <div className={classes.input}>
                        <Typography variant="h6" className={classes.label}>
                            E-mail
                        </Typography>

                        <TextField
                            id="email"
                            type="email"
                            placeholder="E-mail"
                            InputProps={{
                                classes: {
                                    input: classes.textField,
                                },
                                disableUnderline: true,
                            }}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                            fullWidth
                        />
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button2
                            disabled={formik.values.email.length === 0}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            size="large"
                            loading={loading}
                            color="primary"
                            fullWidth
                            label={'RECUPERAR SENHA'}
                        />
                    </div>
                    <Link to="/" className={classes.linkRecover}>
                        <b className={classes.linkRecoverBold}>Voltar</b>
                    </Link>
                </div>
            </div>
        </Grid>
    )
}

export default RecoverPassword
