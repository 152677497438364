import React from 'react'
import { Router, Switch } from 'react-router-dom'

import Route from './route/components/Route'
import history from './config/history'

import Login from './pages/login/index'
import Home from './pages/home/index'
import RecoverPassword from './pages/recoverPassword/index'
import ResetPassword from './pages/resetPassword/index'

import Layout from './components/Layout/Layout'
import './themes/global.scss'

function App() {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={Login} isPrivate={false} />
                <Route
                    exact
                    path="/recover-password"
                    component={RecoverPassword}
                    isPrivate={false}
                />
                <Route
                    path="/recuperar-senha/:token"
                    component={ResetPassword}
                    isPrivate={false}
                />
                <Route exact path="/home" component={Home} isPrivate={true} />
                <Layout />
            </Switch>
        </Router>
    )
}

export default App
