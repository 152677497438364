import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Creators } from '../../store/ducks/auth'

import Button from '../../components/Button'

import adminLogoLogin from '../../assets/images/Logo.png'
import backgroundLogin from '../../assets/images/Fundo.png'
import ToolBar from '../../components/ToolBar'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
    },
    logoContainer: {
        width: '6.25rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoImage: {
        marginBottom: theme.spacing(4),
    },
    formContainer: {
        background: theme.palette.background.default,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.border.default,
        borderRadius: 10,
        padding: '1.2rem',
        minWidth: 320,
        width: '23%',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        marginBottom: 19,
        fontSize: '1.2rem',

        '& .MuiInputBase-input': {
            borderBottom: '1px solid #363636',
            fontSize: '1rem',
            color: '#a9a9a9',
        },
    },
    buttonContainer: {
        marginTop: 10,
        marginBottom: 22,
        ' & .MuiButton-containedSizeLarge': {
            padding: '10px 22px',
            fontSize: '1rem',
        },
    },
    label: { color: theme.palette.text.black },
    linkRecover: {
        marginBottom: 10,
        fontSize: '0.8rem',
        textAlign: 'center',
        textDecoration: 'none',
        color: theme.palette.text.grey,
        '& b': {
            color: theme.palette.primary.main,
        },
    },
}))

function Login() {
    const dispatch = useDispatch()
    const [login, setLogin] = useState('')
    const [loading, setLoading] = useState(false)
    const classes = useStyles()

    const formik = useFormik({
        initialValues: {
            email: '',
            document: '',
            password: '',
        },
        validationSchema: yup.object({
            email: yup
                .string('E-mail')
                .email('Digite um endereço de e-mail válido.')
                .required('Campo obrigatório'),
            password: yup.string('Senha').required('Campo obrigatório'),
        }),
        onSubmit: async (values) => {
            setLoading(true)
            dispatch(Creators.signInRequest(values.email, values.password))
            setLoading(false)
        },
    })

    return (
        <Grid
            container
            className={classes.container}
            style={{
                backgroundImage: `url(${backgroundLogin})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
            }}
        >
            <ToolBar />
            <div className={classes.logoContainer}>
                <img
                    src={adminLogoLogin}
                    alt="logo"
                    className={classes.logoImage}
                />
            </div>
            <div className={classes.formContainer}>
                <div className={classes.form}>
                    <div className={classes.input}>
                        <Typography variant="h5" className={classes.label}>
                            Login/E-mail
                        </Typography>

                        <TextField
                            id="login"
                            InputProps={{
                                classes: {
                                    input: classes.textField,
                                },
                                disableUnderline: true,
                            }}
                            value={login}
                            placeholder="exemplo@exemplo.com"
                            onChange={(e) => {
                                setLogin(e.target.value)
                                formik.setFieldValue('email', e.target.value)
                            }}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                            fullWidth
                        />
                    </div>
                    <div className={classes.input}>
                        <Typography variant="h5" className={classes.label}>
                            Senha
                        </Typography>
                        <TextField
                            id="password"
                            InputProps={{
                                classes: {
                                    input: classes.textField,
                                },
                                disableUnderline: true,
                            }}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.password &&
                                Boolean(formik.errors.password)
                            }
                            helperText={
                                formik.touched.password &&
                                formik.errors.password
                            }
                            placeholder="Senha"
                            type="password"
                            fullWidth
                        />
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            size="large"
                            loading={loading}
                            color="primary"
                            fullWidth
                            label={'ENTRAR'}
                        />
                    </div>
                    <Link
                        to="/recover-password"
                        className={classes.linkRecover}
                    >
                        Esqueceu a{' '}
                        <b className={classes.linkRecoverBold}>senha</b>?
                    </Link>
                </div>
            </div>
        </Grid>
    )
}

export default withRouter(Login)
