import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
    },
    content: {
        display: 'flex',
        width: '100%',
        marginTop: '3.4rem',
        '& .MuiDrawer-paper': {
            top: '3.3rem',
            color: theme.palette.primary.contrastText,
            boxSizing: 'border-box',
            border: 'none',
            backgroundColor: 'transparent',
        },
        '& .MuiDrawer-paper:hover': {
            width: '14.5rem',
        },
        '& .MuiListItem-root.Mui-disabled': {
            opacity: 1,
        },
        '& .MuiListItem-root.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiListItem-root:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    container: {
        width: '100%',
        height: '100%',
        overflowX: ' hidden',
        overflowY: 'auto',
    },
}))
