import React from 'react'
import { List, Drawer } from '@material-ui/core'
import clsx from 'clsx'

import MenuItem2 from '../../components/MenuItem/index'

import useStyles from './styles'
import { withRouter } from 'react-router'

function Menu({ open, location }) {
    const classes = useStyles()

    return (
        <>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.menuOpen]: open,
                    [classes.menuClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.menuOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
            >
                <List
                    className={classes.sidebar}
                    style={{ width: open ? '14.5rem' : '3rem' }}
                >
                    <MenuItem2 open={open} location={location} />
                </List>
            </Drawer>
        </>
    )
}
export default withRouter(Menu)
