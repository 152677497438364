import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { ArrowDropDown } from '@material-ui/icons'
import { Divider } from '@material-ui/core'
import adminLogoLogin from '../../assets/images/Logo-white.png'
import Header from '../Header'
import { store } from '../../store'

const useStyles = makeStyles((theme) => ({
    root: {
        top: 0,
        position: 'fixed',
        width: '100%',
    },
    toolbar: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
    },
    menuButton: {
        justifyContent: 'center',
        width: '2.8rem',
    },
    title: {
        flexGrow: 1,
        minHeight: '2.8rem',
    },
    divider: {
        background: theme.palette.primary.main,
        height: 7,
    },
    perfil: {
        marginLeft: 10,
        marginRight: 10,
    },
    logoImage: {
        height: '2rem',
    },
    hide: {
        display: 'none',
    },
    hidee: {
        display: 'flex',
        width: '12rem',
        paddingLeft: '1.2rem',
    },
}))

export default function Sidebar({ open, setOpen }) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const openMenu = Boolean(anchorEl)
    const { user } = store.getState().auth

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                color="secondary"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar className={classes.toolbar}>
                    <>
                        <div
                            className={clsx(classes.hide, {
                                [classes.hidee]: open,
                            })}
                        >
                            <img
                                src={adminLogoLogin}
                                alt="logo"
                                className={classes.logoImage}
                            />
                        </div>
                        <IconButton
                            className={classes.menuButton}
                            onClick={setOpen}
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuIcon />
                        </IconButton>
                    </>

                    <Typography
                        variant="h6"
                        className={classes.title}
                    ></Typography>

                    <div>
                        <IconButton
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            style={{ padding: '0.5rem' }}
                        >
                            <AccountCircle
                                style={{ fontSize: '1.3em', margin: 3 }}
                            />
                            <Typography variant="h4" className={classes.perfil}>
                                {user?.name ? user.name.split(' ')[0] : ''}
                            </Typography>
                            <ArrowDropDown></ArrowDropDown>
                        </IconButton>

                        <Header
                            anchorEl={anchorEl}
                            openMenu={openMenu}
                            handleClose={handleClose}
                        />
                    </div>
                </Toolbar>
                <Divider className={classes.divider} />
            </AppBar>
        </div>
    )
}
