import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import history from '../../config/history'
import { useDispatch } from 'react-redux'
import { Creators } from '../../store/ducks/auth'

import useStyles from './styles'

function Header({ anchorEl, openMenu, handleClose }) {
    const classes = useStyles()
    const dispatch = useDispatch()

    function handleSignOut() {
        dispatch(Creators.signOut())
    }

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                className={classes.headerMenu}
                classes={{ paper: classes.profileMenu }}
            >
                <MenuItem key={1}>
                    <Typography onClick={() => history.push('/profile')}>
                        Minha conta
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem key={2} onClick={handleSignOut}>
                    <Typography className={classes.logout}>Sair</Typography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default Header
