import React, { Suspense } from 'react'
import { Switch } from 'react-router-dom'
import { routes } from '../pages'
import Route from './components/Route'
import Loading from '../components/Loading'

const Routes = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                {routes.map((route, index) => (
                    <Route
                        isPrivate={route.private}
                        component={route.component}
                        path={route.path}
                        exact={route.exact}
                        key={index}
                    />
                ))}
            </Switch>
        </Suspense>
    )
}

export default Routes
