import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
    signInRequest: ['email', 'password'],
    signInSuccess: ['access_token', 'refresh_token', 'user'],
    signFailure: [],
    setToken: ['access_token'],
    updateUser: ['user'],
    signOut: [],
    setRecoveryState: ['isRecoveryPass'],
})

const INITIAL_STATE = {
    access_token: null,
    refresh_token: null,
    user: null,
    signed: false,
    isRecoveryPass: false,
}

const signInRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        loading: true,
    }
}

const signInSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        access_token: action.access_token,
        refresh_token: action.refresh_token,
        signed: true,
        user: action.user,
        loading: false,
    }
}

const signFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        loading: false,
    }
}
const setToken = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        access_token: action.access_token,
        loading: false,
    }
}

const updateUser = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        user: action.user,
        loading: false,
    }
}

const setRecoveryState = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isRecoveryPass: action.isRecoveryPass,
    }
}

const signOut = (state = INITIAL_STATE) => {
    return {
        ...state,
        access_token: null,
        refresh_token: null,
        user: null,
        signed: false,
        loading: false,
    }
}

export default createReducer(INITIAL_STATE, {
    [Types.SIGN_IN_REQUEST]: signInRequest,
    [Types.SIGN_IN_SUCCESS]: signInSuccess,
    [Types.SIGN_FAILURE]: signFailure,
    [Types.SIGN_OUT]: signOut,
    [Types.SET_TOKEN]: setToken,
    [Types.UPDATE_USER]: updateUser,
    [Types.SET_RECOVERY_STATE]: setRecoveryState,
})
